<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-truck-delivery</v-icon> Entrada de producto</h1><br>
        <v-layout wrap>
          <v-flex xs11>
            <v-autocomplete
              v-model="selectClient"
              :items="this.$store.state.productor"
              item-text="nombre"
              item-value="id"
              flat
              hide-no-data
              label="Buscar productor"
              prepend-inner-icon="mdi-account-search"
              solo-inverted
              @change="getFincas(); read();"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs1>
            <v-btn v-on:click="clear" text class="btnMini">
              <v-icon large>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4>
            <v-select
              label="Finca/s"
              outlined
              multiple
              v-model="finca"
              :items="fincasProductor"
              item-text="nombre"
              item-value="id"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-select
              label="Producto"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-menu
              v-model="menuDate"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormatted"
                  label="Fecha de entrada"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaEntrada');"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInput"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs3>
            <v-select
              label="Calidad / categoría"
              outlined
              v-model="calidad"
              :items="calidades"
              item-text="nombre"
              item-value="nombre"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-text-field
              label="Kilos"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="kilos"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 v-if="producto!='Frutales'">
            <v-text-field
              label="Rendimiento"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="rendimiento"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 v-if="producto!='Aceituna'">
            <v-text-field
              label="Descuento %"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="descuento"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 v-if="producto=='Aceituna'">
            <v-text-field
              label="Acidez (grados)"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="acidez"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Total"
              outlined
              readonly
              type="number"
              min="0"
              step="0.5"
              v-model="total"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-text-field
              label="Nº albarán"
              outlined
              v-model="albaran"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-select
              label="Reparto de kilos"
              outlined
              :items="repartos"
              hint="Solo en caso de haber seleccionado varias fincas"
              v-model="reparto"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-text-field
              label="Comentario"
              outlined
              v-model="comentario"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" :loading="loading" :disabled="loading" @click="save"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="entradas"
          show-expand
          item-key="albaran"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item.comentario }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editarEntrada(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="eliminarEntrada(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Producto',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      selectClient: null,
      loading: false,
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      repartos: ['Equitativo', 'Según superficie', 'Según plantas'],
      menuDate: '',
      dateFormatted: '',
      dateInput: '',
      fincasProductor: [],
      finca: [],
      calidades: [],
      calidad: null,
      kilos: '',
      rendimiento: '',
      acidez: '',
      total: '',
      descuento: '',
      albaran: '',
      reparto: null,
      comentario: '',
      producto: null,
      headers: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        { text: 'Finca', value: 'finca' },
        { text: 'Tipo producto', value: 'tipoproducto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Rendimiento', value: 'rendimiento' },
        { text: 'Acidez / Humedad', value: 'acidez' },
        { text: 'Albarán', value: 'albaran' },
        { text: '', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      entradas: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.dateInput = basics.getDate();
    this.dateFormatted = basics.formatDate(basics.getDate(),"dd/mm/yyyy");
  },
  methods:{
    changeDateFormatted(field){
      switch(field){
        case "fechaEntrada":
          if(this.dateInput){
            this.dateFormatted = basics.formatDate(this.dateInput,"dd/mm/yyyy");
          }
          break;
      }
    },
    clear(){
      this.selectClient = null;
      this.menuDate = '',
      this.dateFormatted = '',
      this.dateInput = '',
      this.fincasProductor = [],
      this.finca = [],
      this.calidad = null,
      this.kilos = '',
      this.rendimiento = '',
      this.acidez = '',
      this.total = '',
      this.descuento = '',
      this.albaran = '',
      this.reparto = null,
      this.comentario = '',
      this.producto = null,
      this.calidades = [],
      this.entradas = [];
    },
    getDataToSave(finca, kilos){
      let data = null;
      if(this.producto=="Aceituna"){
        data = {
          idProductor: this.selectClient,
          tipo: this.producto,
          idFinca: finca,
          fecha: this.dateInput,
          kilos: kilos,
          comentario: this.comentario,
          coop: 1,
          rendimiento: this.rendimiento,
          rendimiento2: this.rendimiento,
          acidez: this.acidez,
          aceite: (kilos * (this.rendimiento/100)),
          calidad: this.calidad,
          albaran: this.albaran,
        };
      }
      if(this.producto=="Cascara"){
        data = {
          idProductor: this.selectClient,
          tipo: this.producto,
          idFinca: finca,
          fecha: this.dateInput,
          kilos: kilos,
          comentario: this.comentario,
          coop: 1,
          rendimiento: this.rendimiento,
          humedad: this.descuento,
          pipa: ((kilos * (this.rendimiento/100)) - ((kilos * (this.rendimiento/100))*(this.descuento/100))),
          albaran: this.albaran,
        };
      }
      if(this.producto=="Frutales"){
        data = {
          idProductor: this.selectClient,
          tipo: this.producto,
          idFinca: finca,
          fecha: this.dateInput,
          kilos: kilos,
          comentario: this.comentario,
          coop: 1,
          albaran: this.albaran,
          categoria: this.calidad,
          descuento: this.descuento,
        };
      }
      return data;
    },
    async save(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      this.loading = true;
      if(this.selectClient != null && this.finca.length != 0){
        let data, response;
        if(this.finca.length=1){
          data = this.getDataToSave(this.finca[0], this.kilos);
          response = await fetch(
            `${BASE_URL}/entrada`,
            {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
        }else{
          if(this.reparto != null){
            if(this.reparto == "Equitativo"){
              let calc = this.kilos / this.finca.length;
              for(let i = 0; i < this.finca.length; i++){
                data = this.getDataToSave(this.finca[i], calc);
                response = await fetch(
                  `${BASE_URL}/entrada`,
                  {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                  }
                );
              }
            }
            if(this.reparto == "Según superficie"){
              let fincas = []; let total = 0;
              for(let i = 0; i < this.finca.length; i++){
                let idFinca = this.finca[i];
                const responseFinca = await genericReload(this.$store.state.auth, `finca/${idFinca}`);
                if (responseFinca.status === 200) {
                  const json = await response.json();
                  fincas.push({id: idFinca, superficie: parseFloat(json.body[i].superficie)});
                  total += parseFloat(json.body[i].superficie);
                }
              }
              for(let i = 0; i < this.fincas.length; i++){
                let calc = (this.fincas[i].superficie * this.kilos) / total;
                data = this.getDataToSave(this.finca[i], calc);
                response = await fetch(
                  `${BASE_URL}/entrada`,
                  {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                  }
                );
              }
            }
            if(this.reparto == "Según plantas"){
              let fincas = []; let total = 0;
              for(let i = 0; i < this.finca.length; i++){
                let idFinca = this.finca[i];
                const responseFinca = await genericReload(this.$store.state.auth, `finca/${idFinca}`);
                if (responseFinca.status === 200) {
                  const json = await response.json();
                  fincas.push({id: idFinca, plantas: parseFloat(json.body[i].plantas)});
                  total += parseFloat(json.body[i].plantas);
                }
              }
              for(let i = 0; i < this.fincas.length; i++){
                let calc = (this.fincas[i].plantas * this.kilos) / total;
                data = this.getDataToSave(this.finca[i], calc);
                response = await fetch(
                  `${BASE_URL}/entrada`,
                  {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                  }
                );
              }
            }
          }else{
            this.$refs.snackbar.setNew(true, "ERROR. Es necesario añadir el tipo de reparto a realizar", "Cerrar", "red");
            this.loading = false;
          }
        }
        switch (response.status) {
          case 200:
            this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
            this.clear();
            this.read();
            this.loading = false;
            break;
          default:
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
            this.loading = false;
            break;
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Es necesario añadir a que productor se le está haciendo la entrada de producto", "Cerrar", "red");
        this.loading = false;
      }
    },
    async read(){
      this.entradas = [];
      if(this.selectClient){
        const response = await genericReload(this.$store.state.auth, `entrada/?idProductor=${this.selectClient}`);
        if (response.status === 200) {
          const json = await response.json();
          for(let i = 0; i<json.body.length; i++){
            let getDataFinca = this.fincasProductor.find((f) => json.body[i].idFinca == f.id);
            let product = '';
            if(json.body[i].calidad){
              product = json.body[i].calidad;
            }else{
              if(json.body[i].categoria){
                product = json.body[i].categoria;
              }
            }
            this.entradas.push({
              id: json.body[i].id, 
              fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"), 
              finca: getDataFinca.nombre, 
              tipoproducto: json.body[i].tipo,
              calidad: product,
              kilos: json.body[i].kilos,
              rendimiento: json.body[i].rendimiento,
              acidez: json.body[i].acidez,
              albaran: json.body[i].albaran,
              comentario: json.body[i].comentario,
            });
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de entradas anteriores", "Cerrar", "red");
        }
      }
    },
    async editarEntrada(item){
      this.$refs.dialog.setNew("Editar entrada", "", "editInput", item).then(async(confirm) => {
        if(confirm.response){
          let data = null;
          if(this.producto=="Aceituna"){
            data = {
              idProductor: this.selectClient,
              tipo: item.tipoproducto,
              fecha: confirm.fecha,
              kilos: confirm.kilos,
              comentario: confirm.comentario,
              rendimiento: confirm.rendimiento,
              rendimiento2: confirm.rendimiento,
              acidez: confirm.acidez,
              aceite: (confirm.kilos * (confirm.rendimiento/100)),
              albaran: confirm.albaran,
            };
          }
          if(this.producto=="Cascara"){
            data = {
              idProductor: this.selectClient,
              tipo: item.tipoproducto,
              fecha: confirm.fecha,
              kilos: confirm.kilos,
              comentario: confirm.comentario,
              rendimiento: confirm.rendimiento,
              humedad: confirm.acidez,
              pipa: ((confirm.kilos * (confirm.rendimiento/100)) - ((confirm.kilos * (confirm.rendimiento/100))*(confirm.descuento/100))),
              albaran: confirm.albaran,
            };
          }
          if(this.producto=="Frutales"){
            data = {
              idProductor: this.selectClient,
              tipo: item.tipoproducto,
              fecha: confirm.dateInput,
              kilos: confirm.kilos,
              comentario: confirm.comentario,
              albaran: confirm.albaran,
              descuento: confirm.descuento,
            };
          }
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/entrada/${item.id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Entrada de producto editada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Entrada de producto no editada", "Cerrar", "red");
              break;
          }
        }
      })
    },
    eliminarEntrada(item){
      this.$refs.dialog.setNew("Eliminar entrada", "¿Estás seguro de querer eliminar esta entrada?", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const data = {
            idProductor: this.selectClient, 
            tipo: item.tipoproducto,
          };

          const response = await fetch(
            `${BASE_URL}/entrada/${item.id}`,
            {
              method: 'DELETE',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Entrada eliminada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Entrada no eliminada", "Cerrar", "red");
              break;
          }
        }
      });
    },
    async getFincas(){
      this.fincasProductor = [];
      if(this.selectClient){
        const response = await genericReload(this.$store.state.auth, `finca/?idProductor=${this.selectClient}`);
        if (response.status === 200) {
          const json = await response.json();
          for(let i = 0; i<json.body.length; i++){
            this.fincasProductor.push({
              id: json.body[i].id, 
              nombre: json.body[i].nombre,
            });
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las fincas", "Cerrar", "red");
        }
      }
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
    calculaTotal(){
      if(this.producto=="Aceituna"){
        this.total = (this.kilos * (this.rendimiento/100));
      }
      if(this.producto=="Cascara"){
        this.total = (this.kilos - (this.kilos*(this.descuento/100)));
      }
      if(this.producto=="Frutales"){
        this.total = (this.kilos - (this.kilos*(this.descuento/100)));
      }
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>